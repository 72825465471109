import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container } from 'react-bootstrap'
import moment from 'moment'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

//components
import Layout from '../components/layout'
import CollectionCards from '../components/collection-cards'
import OEmbed from '../components/oEmbed'
import SEO from "../components/seo"
import LinkCard from '../components/link-card'
import RenderMultiLine from '../components/RenderMultiline'

const CollectionPageTemplate = ({data}) => {    
    const collectionPageData = data.contentfulCollection
    const Links = collectionPageData.links.map((link)=> (<LinkCard key={link.title} link={link}/>));
    const time = moment(collectionPageData.createdAt).format("HH:mm - DD MMMM YYYY")
    const Tags = collectionPageData.tags ? collectionPageData.tags.map((tag) => (<TagItem key={tag.tag} tag={tag}/>)) : null;
    
    return(
        <Layout hideNav={true}>
            <SEO url={`https://bibliotheekverrijker.nl/inspiratie/${collectionPageData.slug}/`} title={collectionPageData.title} description={(collectionPageData.introduction ? collectionPageData.introduction.introduction : '')} image={collectionPageData.image.fluid.src} />
            <section className="section">
                <Container>
                    <article className="collection has-padding-large has-bg-white">
                        <header className="collection-header">
                            <time className="collection-meta" value={collectionPageData.createdAt}>{time}</time>
                            <h1 className="heading collection-heading">{collectionPageData.title}</h1>
                            <div className="collection-intro wysiwyg">
                                {collectionPageData.introduction!==null ? <RenderMultiLine string={collectionPageData.introduction.introduction}/> : null}
                            </div>
                        </header>
                        <section>
                            <div className="collection-content">
                                <div className="collection-embed">
                                    {collectionPageData.image.fluid!==null &&
                                        <Img fluid={collectionPageData.image.fluid} className="collection-embed-image" />
                                    }
                                    <OEmbed url={collectionPageData.oEmbed} title={collectionPageData.title} image={collectionPageData.image}/>
                                </div>
                                {collectionPageData.description!== null &&
                                    <div className="section-block block wysiwyg">
                                        {documentToReactComponents(collectionPageData.description.json)}
                                    </div>
                                }
                                {Tags!== null &&                                    
                                    <div className="section-block block">
                                        <h2 className="heading block-heading">Tags</h2>
                                        <span className="collection-tags">
                                            {Tags}
                                        </span>
                                    </div>
                                }
                            </div>
                        </section>
                    </article>
                </Container>
            </section>
            
            <section className="section">
                <Container fluid>
                    <div className="section-intro">
                        <h2 className="heading section-heading">
                            {(collectionPageData.linksTitle!==null ? collectionPageData.linksTitle : 'Een greep uit de collectie van de bibliotheek') }
                        </h2>
                    </div>
                    <ul className="cards cards-links">
                        {Links}
                    </ul>
                </Container>
            </section>
            {collectionPageData.gerelateerdeCollecties &&
                <section className="section">
                    <Container fluid>
                        <div className="section-intro">
                            <h2 className="heading section-heading">Gerelateerde collecties</h2>
                        </div>
                        <CollectionCards collectionItems={collectionPageData.gerelateerdeCollecties}/>
                    </Container>
                </section>
            }
        </Layout>
    )
}

const TagItem = ({tag}) => {    
    return(
        <Link to={`/${tag.slug}`} className="btn btn-primary btn-sm collection-tag tag">{tag.tag}</Link>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulCollection( slug: { eq: $slug }) {
            title
            slug
            createdAt
            oEmbed
            introduction {
                introduction
            }
            description {
                json
            }
            linksTitle
            image {
                fluid(maxWidth:1024, maxHeight:1024){
                    ...GatsbyContentfulFluid
                }
                title
            }
            links {
                title
                description {
                    description
                }
                url
                image {
                    fluid(maxWidth:400, maxHeight:400){
                        ...GatsbyContentfulFluid
                    }
                    title
                }
                type {
                    identifier
                    type
                    slug
                }
                partner
                tags {
                    tag
                    slug
                }
            }
            tags {
                tag
                slug
            }
            gerelateerdeCollecties {
                slug,
                title,
                oEmbed,
                image {
                    fluid(maxWidth:400, maxHeight:400){
                        ...GatsbyContentfulFluid
                    }
                    title
                }
            }
        }
    }
`
export default CollectionPageTemplate
